/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, { useState, useContext, useEffect } from 'react';
import { Form, TextInput, DateInput, TimeInput, Select, Button, Card, Animate, Grid, useAPI } from 'components/lib';

import ClassNames from 'classnames';
import Style from './personalchart.module.scss';

// -------
import { data } from '../../lib/data_reference';
// import { util } from '../../lib/util';
import moment from 'moment';
// -------
import Axios from 'axios';

async function generateBazi(birthday, time, gender) {

  const res = await Axios({

    method: 'post',
    url: '/api/genbz',
    data: { birthday: birthday, time: time, gender: gender }

  });

  if (res.data) {
    return res.data;
  } else {
    return null;
  }
}

async function postToServer(endpoint, data) {
  try {
    const token = localStorage.getItem("authToken"); // Retrieve token
    const headers = {
      'Content-Type': 'application/json',
    };

    const response = await Axios.post(endpoint, data, { headers });
    return response.data;
  } catch (error) {
    console.error("Error posting data to server:", error);
    throw error;
  }
}

export function PersonalBaziChart(props) {
  const user_new = useAPI('/api/user');
  const today_bz = useAPI('/api/bztoday');
  const list_of_profile = useAPI('/api/listofprofile');

  const genderOptions = [
    { value: 0, label: 'Female' },
    { value: 1, label: 'Male' }
  ]

  const [bz_chart, setBz_chart] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [birthTime, setBirthTime] = useState(null);
  const [birthName, setBirthName] = useState(null);
  const [birthGender, setBirthGender] = useState(0);
  const [age, setAge] = useState(0);
  const [dm, setDm] = useState(null);
  const [gua, setGua] = useState(null);
  const [current_luck_pillar, setCurrent_luck_pillar] = useState(0);
  const [firstProfile, setFirstProfile] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [dataProfile, setDataProfile] = useState(null);

  let today_bz_chart = null;
  let bday = null;
  let readonly = false;
  let today = moment();

  // -----------------
  // useEffect
  // -----------------
  useEffect(async () => {
    if (firstProfile) {
      changeProfile("", firstProfile?.id);
      setSelectedProfile(dataProfile[0]);
    }
  }, [firstProfile]);

  useEffect(async () => {
    if (birthName) {
      document.title = "Bazi Chart -" + birthName;
    }
  }, [birthName]);

  useEffect(async () => {
    let bz = await recalculateChart();
  }, [birthDate, birthTime, birthGender]);
  // -----------------

  // -----------------
  // default value loading
  // -----------------
  if (!today_bz.loading && today_bz?.data && !today_bz_chart) {
    today_bz_chart = today_bz.data;
  }

  if (!list_of_profile.loading && list_of_profile.data && !firstProfile) {
    // Populate dataProfile with id and name of each item in list_of_profile
    setDataProfile(list_of_profile.data.map(profile => ({
      value: profile.id,
      label: profile.name
    })));
    setFirstProfile(list_of_profile.data[0]);
  }
  // -----------------

  // -----------------
  // profile functions
  // -----------------
  const saveProfile = async () => {
    const payload = {
      name: birthName,
      birth_date: birthDate,
      birth_time: birthTime,
      gender: birthGender
    };

    // Confirm with the user before saving
    const userConfirmed = window.confirm(
      `Are you sure you want to save the following profile?\nNOTE: You can change the name. Click "Cancel" to change the name, if need.\n\n` +
      `Name: ${payload.name}\n` +
      `Birth Date: ${payload.birth_date}\n` +
      `Birth Time: ${payload.birth_time}\n` +
      `Gender: ${payload.gender === 0 ? "Female" : "Male"}`
    );

    // If user cancels, exit the function
    if (!userConfirmed) {
      // alert("Profile save canceled.");
      return;
    }

    postToServer("/api/listofprofile", payload)
      .then((data) => { 
        const new_profile = data.data;
        dataProfile.push({
          value: new_profile.id,
          label: new_profile.name
        });
        setDataProfile(dataProfile);
        list_of_profile.data.push(new_profile);
        changeProfile("", new_profile.id);
        setSelectedProfile(dataProfile[dataProfile.length-1]);
      })
      .catch((err) => { console.error("Error:", err) });
  }

  async function updateDayPillar(parseBzChart) {
    let bday_str = parseBzChart.birthday[0] + "-" + parseBzChart.birthday[1] + "-" + parseBzChart.birthday[2] + " " + parseBzChart.birthday[3] + ":" + parseBzChart.birthday[4];
    bday = moment(bday_str, "YYYY-M-D");
    let cal_age = today.diff(bday, 'years');

    for (let dayun_index = 9; dayun_index >= 0; dayun_index--) {
      if (cal_age >= parseBzChart.dayun_age[dayun_index] && cal_age < parseBzChart.dayun_age[dayun_index + 1]) {
        setCurrent_luck_pillar(dayun_index);
      }
    }
    setAge(cal_age);
  }

  async function changeProfile(type, value = -1) {
    const selected_profile = list_of_profile.data.find(profile => profile.id == value);
    if(selected_profile)
    {
      setBirthDate(moment(selected_profile.birth_date).format("YYYY-MM-DD"));
      setBirthTime(selected_profile.birth_time);
      setBirthGender(selected_profile.gender);
      setBirthName(selected_profile.name);
    }
  }

  function update(a, b, c) {
    switch (a) {
      case "birthdate":
        setBirthDate(b);
        break;

      case "birthtime":
        setBirthTime(b);
        break;

      case "gender":
        setBirthGender(b);
        break;

      default:
        break;
    }
  }

  function updateName(a, b, c, d) {
    setBirthName(b);
  }
  // -----------------

  // -----------------
  // recalculate Chart
  // -----------------
  async function recalculateChart() {
    let bd = birthDate;
    let bt = birthTime;
    let g = birthGender;

    let result = await generateBazi(bd, bt, g);
    let bz = result.data;
    setDm(bz.tg[3]);
    setGua(bz.gua);
    setBz_chart(bz);
    updateDayPillar(bz);

    return bz;
  }
  // -----------------

  // -----------------
  // rendering
  // -----------------
  let renderTenYearTable = ((start, end, the12phase) => {
    const content = [];
    for (let dayun_index = start; dayun_index >= end; dayun_index--) {
      content.push(
        <Grid className={Style.tenyeartable_grid_column} cols='1'>
          <span className={
            ClassNames([
              Style.bz_container_header,
              ((age >= bz_chart.dayun_age[dayun_index] && age < bz_chart.dayun_age[dayun_index + 1]) ? Style.current_luck_pillar : "")
            ])
          }>{bz_chart.dayun_age[dayun_index]} ({bz_chart.birthday[0] + bz_chart.dayun_age[dayun_index]})</span>
          <span className={Style.bz_container_tg}>
            <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[bz_chart.dayun_tg[dayun_index]]]])}>{bz_chart.dayun_tg[dayun_index]}</span><span className={Style.main_element_10god}>{data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[dayun_index]]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[dayun_index]]]}</span></span>
            <span className={Style.main_element_eng}>{data.reference_element_english[bz_chart.dayun_tg[dayun_index]]}</span>
            <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_tg[dayun_index]]}</span>
          </span>
          <span className={Style.bz_container_eb}>
            <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[bz_chart.dayun_eb_element[dayun_index]]]])}>{bz_chart.dayun_eb[dayun_index]}</span>
            {
              (bz_chart.kongwang[3] === bz_chart.dayun_eb[dayun_index] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[dayun_index]) &&
              <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
            }<br />
            <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[bz_chart.dayun_eb[dayun_index]]}</span>
            <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[bz_chart.dayun_eb[dayun_index]]}</span>
            <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_eb_element[dayun_index]]}</span>
          </span>
          <span className={Style.bz_container_hs}>
            <span className={Style.hs_part_left}>
              <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.dayun_hs[dayun_index][1]]]])}>{bz_chart.dayun_hs[dayun_index][1]}</span>
              <span className={Style.hs_bottom}>
                <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.dayun_hs[dayun_index][1]]}
                  <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_hs[dayun_index][1]]}</span></span>
                <span className={Style.hs_element_10god}>
                  {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][1]]}
                </span>
                <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][1]]]}</span>
              </span>
            </span>
            <span className={Style.hs_part_center}>
              <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.dayun_hs[dayun_index][0]]]])}>{bz_chart.dayun_hs[dayun_index][0]}</span>
              <span className={Style.hs_bottom}>
                <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.dayun_hs[dayun_index][0]]}
                  <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_hs[dayun_index][0]]}</span></span>
                <span className={ClassNames([Style.hs_element_10god, Style.hs_element_10god_center])}>
                  {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][0]]}<br />
                </span>
                <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][0]]]}</span>
              </span>
            </span>
            <span className={Style.hs_part_right}>
              <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.dayun_hs[dayun_index][2]]]])}>{bz_chart.dayun_hs[dayun_index][2]}</span>
              <span className={Style.hs_bottom}>
                <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.dayun_hs[dayun_index][2]]}
                  <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_hs[dayun_index][2]]}</span></span>
                <span className={Style.hs_element_10god}>
                  {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][2]]}<br />
                </span>
                <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][2]]]}</span>
              </span>
            </span>
          </span>
          <span className={Style.bz_container_12phase}>
            <span className={ClassNames([Style.hs_element, Style[data.reference_12_phases_luck[the12phase[bz_chart.dayun_eb[dayun_index]]]]])}>{the12phase[bz_chart.dayun_eb[dayun_index]]}</span>
            <span className={Style.hs_element_eng}>{data.reference_12_phases_english[the12phase[bz_chart.dayun_eb[dayun_index]]]}</span>
          </span>
          {/* <span className={Style.bz_container_eb}>
          <span className={Style.main_element_eng}>
            {bz_chart.dayun_starttime[0][2] + " " + data.reference_month[bz_chart.dayun_starttime[0][1]] + " " + bz_chart.dayun_starttime[0][0] + " " + data.displayTime(bz_chart.dayun_starttime[0][3], bz_chart.dayun_starttime[0][4], bz_chart.dayun_starttime[0][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[0][6]]}
          </span>
        </span> */}
          {renderAllTenYearTable((bz_chart.birthday[0] + bz_chart.dayun_age[dayun_index]), dayun_index)}
        </Grid>
      );

    }

    return content;
  });

  let renderAllTenYearTable = ((start_year, dayun_index) => {
    const content = [];
    for (let i = 0; i < 10; i++) {
      let current_year = start_year + i;
      let current_60_pillar = data.find_year_60_pillar(current_year);
      content.push(
        <span className={ClassNames(
          Style.bz_container_10year
        )}>
          <span><span className={Style.year_10year}>{current_year}</span>{current_60_pillar}</span>
        </span>
      );
    }

    return content;
  });

  let renderRelationshipRow = ((start, end, left_element, right_element, type, css_ext = "eb", text) => {
    const content = [];
    let interaction = "";
    let start_column = 0;
    let middle_column = 0;
    let end_column = 0;

    if (start > 1) {
      start_column = start - 1;
    }

    middle_column = end - start + 1;
    end_column = 6 - end;

    switch (type) {
      case "comp":
        interaction = "Compliments";
        break;

      case "ci":
        interaction = "Clash";
        break;

      case "co":
        interaction = "Clash";
        break;

      case "pi":
        interaction = "Produce";
        break;

      case "po":
        interaction = "Produce";
        break;

      case "clash":
        interaction = "Clash";
        break;

      case "combi":
        interaction = "Combine";
        break;

      case "self_punishment":
        interaction = "Self Punishment";
        break;

      case "destruction":
        interaction = "Destruction";
        break;

      case "harm":
        interaction = "Harm";
        break;

      default:
        break;
    }

    content.push(
      <Grid className={Style.bztable_rs_grid} cols='2'>
        <Grid className={Style["bztable_rs_grid_column_" + start_column]} cols='1'>
          <span className={Style.bz_rs_container_header_none}></span>
        </Grid>
        <Grid className={Style["bztable_rs_grid_column_" + middle_column]} cols='1'>
          <span className={Style["bz_rs_container_header_" + data.reference_interaction_css[type] + "_" + css_ext]}><span className={Style["bz_rs_container_text_left"]}>{left_element}</span> {interaction}{text} <span className={Style["bz_rs_container_text_right"]}>{right_element}</span></span>
        </Grid>
        <Grid className={Style["bztable_rs_grid_column_" + end_column]} cols='1'>
          <span className={Style.bz_rs_container_header_none}></span>
        </Grid>
      </Grid>
    );

    return content;
  });

  let renderBaziPillar = ((header_css, header, header_zh, the10god, the12phase, show_de, tg, eb, bz_kw_01, bz_kw_02, hs) => {
    let current_pillar_de = show_de ? data.reference_de[tg + eb] : ["", ""];

    return <Grid className={Style.bztable_grid_column} cols='1'>
      <span className={header_css}>{header}<br />{header_zh}</span>
      <span className={Style.bz_container_tg}>
        <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[tg]]])}>{tg}</span><span className={Style.main_element_10god}>{the10god[tg]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[the10god[tg]]}</span></span>
        <span className={Style.main_element_eng}>{data.reference_element_english[tg]}</span>
        <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[tg]}</span>
      </span>
      <span className={Style.bz_container_eb}>
        <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[data.reference_earthly_branch_element[eb]]]])}>{eb}</span>
        {
          (bz_kw_01 === eb || bz_kw_02 === eb) &&
          <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
        }<br />
        <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[eb]}</span>
        <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[eb]}</span>
        <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[data.reference_earthly_branch_element[eb]]}</span>
      </span>
      <span className={Style.bz_container_hs}>
        <span className={Style.hs_part_left}>
          <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[hs[1]]]])}>{hs[1]}</span>
          <span className={Style.hs_bottom}>
            <span className={Style.hs_element_eng}>{data.reference_element_english[hs[1]]}
              <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[hs[1]]}</span></span>
            <span className={Style.hs_element_10god}>
              {the10god[hs[1]]}
              <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[the10god[hs[1]]]}</span>
            </span>
          </span>
        </span>
        <span className={Style.hs_part_center}>
          <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[hs[0]]]])}>{hs[0]}</span>
          <span className={Style.hs_bottom}>
            <span className={Style.hs_element_eng}>{data.reference_element_english[hs[0]]}
              <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[hs[0]]}</span></span>
            <span className={Style.hs_element_10god}>
              {the10god[hs[0]]}
              <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[the10god[hs[0]]]}</span>
            </span>
          </span>
        </span>
        <span className={Style.hs_part_right}>
          <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[hs[2]]]])}>{hs[2]}</span>
          <span className={Style.hs_bottom}>
            <span className={Style.hs_element_eng}>{data.reference_element_english[hs[2]]}
              <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[hs[2]]}</span></span>
            <span className={Style.hs_element_10god}>
              {data.reference_10_god_from_lunar[the10god[hs[2]]]}
              <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[the10god[hs[2]]]}</span>
            </span>
          </span>
        </span>
      </span>
      <span className={Style.bz_container_12phase}>
        <span className={ClassNames([Style.hs_element, Style[data.reference_12_phases_luck[the12phase[eb]]]])}>{the12phase[eb]}</span>
        <span className={Style.hs_element_eng}>{data.reference_12_phases_english[the12phase[eb]]}</span>
      </span>
      <span className={Style.bz_container_de}>
        <span className={Style.pillar}>
          <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[data.reference_earthly_branch_element[current_pillar_de[0]]]]])}>{current_pillar_de[0]}</span>
          <span className={Style.hs_element_eng}>{data.reference_animal_ping_ying[current_pillar_de[0]]}</span>
          <span className={Style.hs_element_explaination_eng}>{data.reference_animal_english[current_pillar_de[0]]}</span>
          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[data.reference_earthly_branch_element[current_pillar_de[0]]]}</span>
        </span>
        <span className={Style.pillar}>
          <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[data.reference_earthly_branch_element[current_pillar_de[1]]]]])}>{current_pillar_de[1]}</span>
          <span className={Style.hs_element_eng}>{data.reference_animal_ping_ying[current_pillar_de[1]]}</span>
          <span className={Style.hs_element_explaination_eng}>{data.reference_animal_english[current_pillar_de[1]]}</span>
          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[data.reference_earthly_branch_element[current_pillar_de[1]]]}</span>
        </span>
      </span>
    </Grid>
  });
  // -----------------

  return (
    <Animate type='pop'>
      {((!user_new.loading) && bz_chart && today_bz_chart && (!list_of_profile.loading && list_of_profile.data)) &&
        <Grid cols='1'>
          <Card title={'BaZi Charting Software (八字排盤)'} className={[Style.show_overflow_visible, Style.hidePrint]}>
            <Grid className={[Style.basic_info_table_grid, Style.show_overflow_visible, Style.height_higher]} cols='5'>
              <Grid className={[Style.basic_info_table_grid_column, Style.show_overflow_visible, Style.height_higher]} cols='1'>
                <span className={Style.bz_container_header_bigger}>Birth Date and Time<br />出生时辰</span>
              </Grid>
              <Grid className={[Style.basic_info_table_grid_column, Style.show_overflow_visible, Style.long_input, Style.height_higher]} cols='1'>
                <DateInput
                  name="birthdate"
                  value={birthDate}
                  onChange={update}
                  required={readonly}
                  readonly={readonly}
                  max="2050-12-31"
                />
              </Grid>
              <Grid className={[Style.basic_info_table_grid_column, Style.show_overflow_visible, Style.short_input, Style.height_higher]} cols='1'>
                <TimeInput name="birthtime" value={birthTime} onChange={update} />
              </Grid>
              <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                <span className={Style.bz_container_header_bigger}>Gender<br />性别</span>
              </Grid>
              <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                <Select name="gender" value={genderOptions[birthGender]} default={birthGender} onChange={update}
                  options={genderOptions}
                />
              </Grid>
            </Grid>
            <Grid className={[Style.basic_info_table_grid, Style.show_overflow_visible]} cols='5'>
              <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                <span className={Style.bz_container_header_bigger}>Name<br />名字</span>
              </Grid>
              <Grid className={Style.basic_info_table_grid_column_long} cols='1'>
                <TextInput
                  key="key"
                  type="text"
                  form="noone"
                  name="Name"
                  default="def"
                  value={birthName}
                  required={readonly}
                  readonly={readonly}
                  text="text"
                  title="title"
                  errorMessage="ErrorMsg"
                  onChange={updateName}
                />
              </Grid>
              <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                <span className={Style.bz_container_header_bigger}>Profile<br />档案</span>
              </Grid>
              <Grid className={[Style.basic_info_table_grid_column_long, Style.height_higher]} cols='1'>
                {dataProfile && dataProfile.length > 0 &&
                  <Select name="profile" value={selectedProfile} default={selectedProfile.value} onChange={changeProfile}
                    options={dataProfile}
                  />
                }
              </Grid>
              <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                <div className={Style.btnPrint}><Button text='Save' icon='save' iconColor='white' rounded iconButton small action={() => { saveProfile() }} /></div>
              </Grid>
            </Grid>
          </Card>
          <Card
            title=''
            loading={user_new.loading}>
            <Card title={'Basic Information 基本讯息 (' + birthName + ' ' + age + ')'} className={Style.being_card}>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Birth Date and Time<br />出生时辰</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>{bz_chart.birthday[2] + " " + data.reference_month[bz_chart.birthday[1]] + " " + bz_chart.birthday[0]}<br />{data.displayTime(bz_chart.birthday[3], bz_chart.birthday[4], bz_chart.birthday[5]) + " " + data.reference_week_day_full[bz_chart.birthday[6]]}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Gender<br />性别</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>{data.reference_gender_chinese[bz_chart.gender]}<br />{data.reference_gender[bz_chart.gender]}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Day Master<br />日主</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{bz_chart?.dm}</span>
                    <span className={Style.infor_smaller}>{" (" + data.reference_element_english[bz_chart?.dm] + ")"}</span>
                    <br />
                    <span>{data.reference_element_explaination_english[bz_chart?.dm]}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Zodiac Sign<br />生肖</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{bz_chart.eb[5]}</span>
                    <span className={Style.infor_smaller}>{" (" + data.reference_animal_ping_ying[bz_chart.eb[5]] + ")"}</span>
                    <br />
                    <span>{data.reference_animal_english[bz_chart.eb[5]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Nobleman Star<br />天乙贵人</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    {data.reference_nobleman_star[dm].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{star}
                        <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, star, Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[star] + ")"}</span>{index === arr.length - 1 ? '' : ', '}
                      </span>
                    ))}
                    <br />
                    {data.reference_nobleman_star[dm].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{data.reference_animal_english[star]}{index === arr.length - 1 ? '' : ', '}</span>
                    ))}
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Academic Star<br />文昌</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_academic_star[dm], Style.has_element)}>{data.reference_academic_star[dm]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_academic_star[dm], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_academic_star[dm]] + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_academic_star[dm], Style.has_element)}>{data.reference_animal_english[data.reference_academic_star[dm]]}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Peach Blossom Star<br />咸池桃花</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_peach_blossom[bz_chart.eb[5]], Style.has_element)}>{data.reference_peach_blossom[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_peach_blossom[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_peach_blossom[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_peach_blossom[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_peach_blossom[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_peach_blossom[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_peach_blossom[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_peach_blossom[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_peach_blossom[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_peach_blossom[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_peach_blossom[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Sky Horse Star<br />驿马</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_sky_horse[bz_chart.eb[5]], Style.has_element)}>{data.reference_sky_horse[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_sky_horse[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_sky_horse[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_sky_horse[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_sky_horse[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_sky_horse[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_sky_horse[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_sky_horse[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_sky_horse[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_sky_horse[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_sky_horse[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Solitary Star<br />孤辰</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_solitary_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_solitary_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_solitary_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_solitary_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_solitary_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_solitary_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_solitary_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_solitary_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_solitary_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_solitary_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_solitary_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_solitary_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Life Palace<br />命宫</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    {data.getLifePalace(bz_chart)}
                    <span className={Style.infor_smaller}>{" (" + data.reference_element_english[data.getLifePalace(bz_chart)[0]] + " " + data.reference_animal_ping_ying[data.getLifePalace(bz_chart)[1]] + ")"}</span>
                    <br />
                    <span>{data.reference_element_explaination_english[data.getLifePalace(bz_chart)[0]] + " " + data.reference_animal_english[data.getLifePalace(bz_chart)[1]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Conception Palace<br />胎元</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    {data.getConceptionPalace(bz_chart)}
                    <span className={Style.infor_smaller}>{" (" + data.reference_element_english[data.getConceptionPalace(bz_chart)[0]] + " " + data.reference_animal_ping_ying[data.getConceptionPalace(bz_chart)[1]] + ")"}</span>
                    <br />
                    <span>{data.reference_element_explaination_english[data.getConceptionPalace(bz_chart)[0]] + " " + data.reference_animal_english[data.getConceptionPalace(bz_chart)[1]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Life Gua<br />本命卦</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>{bz_chart?.gua + " " + data.reference_life_gua[bz_chart?.gua]}<br /><span>{data.reference_life_gua_english[bz_chart?.gua]}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='1'>
                <Grid className={Style.basic_info_table_grid_long} cols='1'><span className={Style.bz_container_header}>Favorable Directions&nbsp;本命吉方</span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='8'>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Sheng Qi<br />生气</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["生气"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["生气"]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Tian Yi<br />天医</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["天医"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["天医"]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Yan Nian<br />延年</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["延年"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["延年"]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Fu Wei<br />伏位</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["伏位"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["伏位"]]}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='1'>
                <Grid className={Style.basic_info_table_grid_long} cols='1'><span className={Style.bz_container_header}>Unfavorable Directions&nbsp;本命凶方</span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='8'>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Hou Hai<br />祸害</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["祸害"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["祸害"]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Wu Gui<br />五鬼</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["五鬼"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["五鬼"]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Liu Sha<br />六煞</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["六煞"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["六煞"]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'><span className={Style.bz_container_header}>Jue Ming<br />绝命</span></Grid>
                <Grid className={Style.basic_info_table_grid_column_small} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span>{data.reference_direction_english[data.reference_life_gua_8_mansions[bz_chart?.gua]["绝命"]]}</span>
                    <br />
                    <span>{data.reference_direction_chinese[data.reference_life_gua_8_mansions[bz_chart?.gua]["绝命"]]}</span>
                  </span>
                </Grid>
              </Grid>
            </Card>

            <Card title='Bazi Chart' className={Style.being_card}>
              <Grid className={Style.bztable_grid} cols='7'>
                <Grid className={ClassNames([Style.bztable_grid_column, Style.container_cat])} cols='1'>
                  <span className={ClassNames([Style.bz_container_header, Style.container_cat])}>&nbsp;</span>
                  <span className={ClassNames([Style.bz_container_tg, Style.container_cat])}>Heavenly Stem&nbsp;&nbsp;天干</span>
                  <span className={ClassNames([Style.bz_container_eb, Style.container_cat])}>Earthly Branch&nbsp;&nbsp;地支</span>
                  <span className={ClassNames([Style.bz_container_hs, Style.container_cat])}>Hidden Stem&nbsp;&nbsp;藏干</span>
                  <span className={ClassNames([Style.bz_container_12phase, Style.container_cat])}>12 Phases<br />十二长生</span>
                  <span className={ClassNames([Style.bz_container_de, Style.container_cat])}>DE&nbsp;&nbsp;空亡</span>
                </Grid>
                {renderBaziPillar(Style.bz_container_header, "HOUR", "时", data.reference_bz_10_god[bz_chart.tg[3]], data.reference_12_phases[bz_chart.tg[3]], false, bz_chart.tg[2], bz_chart.eb[2], bz_chart.kongwang[3], bz_chart.kongwang_element[3], bz_chart.hs[2])}
                {renderBaziPillar(Style.bz_container_header, "DAY", "日", data.reference_bz_10_god[bz_chart.tg[3]], data.reference_12_phases[bz_chart.tg[3]], true, bz_chart.tg[3], bz_chart.eb[3], bz_chart.kongwang[3], bz_chart.kongwang_element[3], bz_chart.hs[3])}
                {renderBaziPillar(Style.bz_container_header, "MONTH", "月", data.reference_bz_10_god[bz_chart.tg[3]], data.reference_12_phases[bz_chart.tg[3]], false, bz_chart.tg[4], bz_chart.eb[4], bz_chart.kongwang[3], bz_chart.kongwang_element[3], bz_chart.hs[4])}
                {renderBaziPillar(Style.bz_container_header, "YEAR", "年", data.reference_bz_10_god[bz_chart.tg[3]], data.reference_12_phases[bz_chart.tg[3]], true, bz_chart.tg[5], bz_chart.eb[5], bz_chart.kongwang[3], bz_chart.kongwang_element[3], bz_chart.hs[5])}
                {renderBaziPillar(Style.bz_container_header_sub, "10 YEAR", "十年", data.reference_bz_10_god[bz_chart.tg[3]], data.reference_12_phases[bz_chart.tg[3]], false, bz_chart.dayun_tg[current_luck_pillar], bz_chart.dayun_eb[current_luck_pillar], bz_chart.kongwang[3], bz_chart.kongwang_element[3], bz_chart.dayun_hs[current_luck_pillar])}
                {renderBaziPillar(Style.bz_container_header_sub, "ANNUAL YEAR", "流年", data.reference_bz_10_god[bz_chart.tg[3]], data.reference_12_phases[bz_chart.tg[3]], false, today_bz_chart.tg[5], today_bz_chart.eb[5], bz_chart.kongwang[3], bz_chart.kongwang_element[3], today_bz_chart.hs[5])}
              </Grid>
            </Card>

            <div className={Style.page_break}></div>
            <Card title='10 Year Luck Pillar' className={Style.being_card}>
              <Grid className={Style.tenyeartable_grid} cols='1'>
                <Grid className={Style.tenyeartable_grid_container} cols='10'>
                  {renderTenYearTable(9, 0, data.reference_12_phases[bz_chart.tg[3]])}
                </Grid>
              </Grid>
            </Card>


            <div className={Style.page_break}></div>
            <Card title='Elements Relationship' className={Style.being_card}>
              <Grid className={Style.bztable_grid} cols='6'>
                <Grid className={Style.bztable_grid_column_large} cols='1'><span className={Style.bz_container_header}>HOUR<br />时</span></Grid>
                <Grid className={Style.bztable_grid_column_large} cols='1'><span className={Style.bz_container_header}>DAY<br />日</span></Grid>
                <Grid className={Style.bztable_grid_column_large} cols='1'><span className={Style.bz_container_header}>MONTH<br />月</span></Grid>
                <Grid className={Style.bztable_grid_column_large} cols='1'><span className={Style.bz_container_header}>YEAR<br />年</span></Grid>
                <Grid className={Style.bztable_grid_column_large} cols='1'><span className={Style.bz_container_header_sub}>10 YEAR<br />十年</span></Grid>
                <Grid className={Style.bztable_grid_column_large} cols='1'><span className={Style.bz_container_header_sub}>ANNUAL YEAR<br />流年</span></Grid>
              </Grid>

              {renderRelationshipRow(1, 6, "", "", "", "header", "Natal")}

              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.tg[3]]] && renderRelationshipRow(1, 2, bz_chart.tg[2], bz_chart.tg[3], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.tg[3]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.tg[4]]] && renderRelationshipRow(1, 3, bz_chart.tg[2], bz_chart.tg[4], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.tg[4]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.tg[5]]] && renderRelationshipRow(1, 4, bz_chart.tg[2], bz_chart.tg[5], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.tg[5]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[bz_chart.tg[4]]] && renderRelationshipRow(2, 3, bz_chart.tg[3], bz_chart.tg[4], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[bz_chart.tg[4]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[bz_chart.tg[5]]] && renderRelationshipRow(2, 4, bz_chart.tg[3], bz_chart.tg[5], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[bz_chart.tg[5]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[4]]][data.reference_hs_element[bz_chart.tg[5]]] && renderRelationshipRow(3, 4, bz_chart.tg[4], bz_chart.tg[5], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[4]]][data.reference_hs_element[bz_chart.tg[5]]], "hs")}

              {data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.eb[3]] && renderRelationshipRow(1, 2, bz_chart.eb[2], bz_chart.eb[3], data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.eb[3]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.eb[4]] && renderRelationshipRow(1, 3, bz_chart.eb[2], bz_chart.eb[4], data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.eb[4]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.eb[5]] && renderRelationshipRow(1, 4, bz_chart.eb[2], bz_chart.eb[5], data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.eb[5]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[3] + bz_chart.eb[4]] && renderRelationshipRow(2, 3, bz_chart.eb[3], bz_chart.eb[4], data.reference_branch_6_combi[bz_chart.eb[3] + bz_chart.eb[4]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[3] + bz_chart.eb[5]] && renderRelationshipRow(2, 4, bz_chart.eb[3], bz_chart.eb[5], data.reference_branch_6_combi[bz_chart.eb[3] + bz_chart.eb[5]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[4] + bz_chart.eb[5]] && renderRelationshipRow(3, 4, bz_chart.eb[4], bz_chart.eb[5], data.reference_branch_6_combi[bz_chart.eb[4] + bz_chart.eb[5]].type)}

              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + bz_chart.eb[4]] && renderRelationshipRow(1, 3, bz_chart.eb[2] + ", " + bz_chart.eb[3], bz_chart.eb[4], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + bz_chart.eb[4]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + bz_chart.eb[5]] && renderRelationshipRow(1, 4, bz_chart.eb[2] + ", " + bz_chart.eb[3], bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + bz_chart.eb[5]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[4] + bz_chart.eb[5]] && renderRelationshipRow(1, 4, bz_chart.eb[2] + ", " + bz_chart.eb[4], bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[4] + bz_chart.eb[5]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[4] + bz_chart.eb[5]] && renderRelationshipRow(2, 4, bz_chart.eb[3] + ", " + bz_chart.eb[4], bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[4] + bz_chart.eb[5]].type)}

              {data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.eb[3]] && renderRelationshipRow(1, 2, bz_chart.eb[2], bz_chart.eb[3], data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.eb[3]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.eb[4]] && renderRelationshipRow(1, 3, bz_chart.eb[2], bz_chart.eb[4], data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.eb[4]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.eb[5]] && renderRelationshipRow(1, 4, bz_chart.eb[2], bz_chart.eb[5], data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.eb[5]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[3] + bz_chart.eb[4]] && renderRelationshipRow(2, 3, bz_chart.eb[3], bz_chart.eb[4], data.reference_branch_6_clash[bz_chart.eb[3] + bz_chart.eb[4]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[3] + bz_chart.eb[5]] && renderRelationshipRow(2, 4, bz_chart.eb[3], bz_chart.eb[5], data.reference_branch_6_clash[bz_chart.eb[3] + bz_chart.eb[5]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[4] + bz_chart.eb[5]] && renderRelationshipRow(3, 4, bz_chart.eb[4], bz_chart.eb[5], data.reference_branch_6_clash[bz_chart.eb[4] + bz_chart.eb[5]].type)}

              {data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.eb[3]] && renderRelationshipRow(1, 2, bz_chart.eb[2], bz_chart.eb[3], data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.eb[3]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.eb[4]] && renderRelationshipRow(1, 3, bz_chart.eb[2], bz_chart.eb[4], data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.eb[4]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.eb[5]] && renderRelationshipRow(1, 4, bz_chart.eb[2], bz_chart.eb[5], data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.eb[5]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[3] + bz_chart.eb[4]] && renderRelationshipRow(2, 3, bz_chart.eb[3], bz_chart.eb[4], data.reference_branch_self_punishment[bz_chart.eb[3] + bz_chart.eb[4]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[3] + bz_chart.eb[5]] && renderRelationshipRow(2, 4, bz_chart.eb[3], bz_chart.eb[5], data.reference_branch_self_punishment[bz_chart.eb[3] + bz_chart.eb[5]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[4] + bz_chart.eb[5]] && renderRelationshipRow(3, 4, bz_chart.eb[4], bz_chart.eb[5], data.reference_branch_self_punishment[bz_chart.eb[4] + bz_chart.eb[5]].type)}

              {data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.eb[3]] && renderRelationshipRow(1, 2, bz_chart.eb[2], bz_chart.eb[3], data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.eb[3]].type)}
              {data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.eb[4]] && renderRelationshipRow(1, 3, bz_chart.eb[2], bz_chart.eb[4], data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.eb[4]].type)}
              {data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.eb[5]] && renderRelationshipRow(1, 4, bz_chart.eb[2], bz_chart.eb[5], data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.eb[5]].type)}
              {data.reference_branch_destruction[bz_chart.eb[3] + bz_chart.eb[4]] && renderRelationshipRow(2, 3, bz_chart.eb[3], bz_chart.eb[4], data.reference_branch_destruction[bz_chart.eb[3] + bz_chart.eb[4]].type)}
              {data.reference_branch_destruction[bz_chart.eb[3] + bz_chart.eb[5]] && renderRelationshipRow(2, 4, bz_chart.eb[3], bz_chart.eb[5], data.reference_branch_destruction[bz_chart.eb[3] + bz_chart.eb[5]].type)}
              {data.reference_branch_destruction[bz_chart.eb[4] + bz_chart.eb[5]] && renderRelationshipRow(3, 4, bz_chart.eb[4], bz_chart.eb[5], data.reference_branch_destruction[bz_chart.eb[4] + bz_chart.eb[5]].type)}

              {data.reference_branch_harm[bz_chart.eb[2] + bz_chart.eb[3]] && renderRelationshipRow(1, 2, bz_chart.eb[2], bz_chart.eb[3], data.reference_branch_harm[bz_chart.eb[2] + bz_chart.eb[3]].type)}
              {data.reference_branch_harm[bz_chart.eb[2] + bz_chart.eb[4]] && renderRelationshipRow(1, 3, bz_chart.eb[2], bz_chart.eb[4], data.reference_branch_harm[bz_chart.eb[2] + bz_chart.eb[4]].type)}
              {data.reference_branch_harm[bz_chart.eb[2] + bz_chart.eb[5]] && renderRelationshipRow(1, 4, bz_chart.eb[2], bz_chart.eb[5], data.reference_branch_harm[bz_chart.eb[2] + bz_chart.eb[5]].type)}
              {data.reference_branch_harm[bz_chart.eb[3] + bz_chart.eb[4]] && renderRelationshipRow(2, 3, bz_chart.eb[3], bz_chart.eb[4], data.reference_branch_harm[bz_chart.eb[3] + bz_chart.eb[4]].type)}
              {data.reference_branch_harm[bz_chart.eb[3] + bz_chart.eb[5]] && renderRelationshipRow(2, 4, bz_chart.eb[3], bz_chart.eb[5], data.reference_branch_harm[bz_chart.eb[3] + bz_chart.eb[5]].type)}
              {data.reference_branch_harm[bz_chart.eb[4] + bz_chart.eb[5]] && renderRelationshipRow(3, 4, bz_chart.eb[4], bz_chart.eb[5], data.reference_branch_harm[bz_chart.eb[4] + bz_chart.eb[5]].type)}

              {renderRelationshipRow(1, 6, "", "", "", "header", "10 Year")}

              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]] && renderRelationshipRow(1, 5, bz_chart.tg[2], bz_chart.dayun_tg[current_luck_pillar], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]] && renderRelationshipRow(2, 5, bz_chart.tg[3], bz_chart.dayun_tg[current_luck_pillar], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[4]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]] && renderRelationshipRow(3, 5, bz_chart.tg[4], bz_chart.dayun_tg[current_luck_pillar], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[4]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[5]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]] && renderRelationshipRow(4, 5, bz_chart.tg[5], bz_chart.dayun_tg[current_luck_pillar], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[5]]][data.reference_hs_element[bz_chart.dayun_tg[current_luck_pillar]]], "hs")}

              {data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_combi[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(2, 5, bz_chart.eb[3], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_combi[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(3, 5, bz_chart.eb[4], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_combi[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(4, 5, bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_combi[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}

              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2] + ", " + bz_chart.eb[3], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2] + ", " + bz_chart.eb[4], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2] + ", " + bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(2, 5, bz_chart.eb[3] + ", " + bz_chart.eb[4], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(2, 5, bz_chart.eb[3] + ", " + bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[4] + bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(3, 5, bz_chart.eb[4] + ", " + bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_3_combi[bz_chart.eb[4] + bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}

              {data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_clash[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(2, 5, bz_chart.eb[3], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_clash[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(3, 5, bz_chart.eb[4], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_clash[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(4, 5, bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_6_clash[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}

              {data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_self_punishment[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(2, 5, bz_chart.eb[3], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_self_punishment[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(3, 5, bz_chart.eb[4], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_self_punishment[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(4, 5, bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_self_punishment[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}

              {data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_destruction[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_destruction[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(2, 5, bz_chart.eb[3], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_destruction[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_destruction[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(3, 5, bz_chart.eb[4], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_destruction[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_destruction[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(4, 5, bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_destruction[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}

              {data.reference_branch_harm[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(1, 5, bz_chart.eb[2], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_harm[bz_chart.eb[2] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_harm[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(2, 5, bz_chart.eb[3], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_harm[bz_chart.eb[3] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_harm[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(3, 5, bz_chart.eb[4], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_harm[bz_chart.eb[4] + bz_chart.dayun_eb[current_luck_pillar]].type)}
              {data.reference_branch_harm[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]] && renderRelationshipRow(4, 5, bz_chart.eb[5], bz_chart.dayun_eb[current_luck_pillar], data.reference_branch_harm[bz_chart.eb[5] + bz_chart.dayun_eb[current_luck_pillar]].type)}

              {renderRelationshipRow(1, 6, "", "", "", "header", "Annual")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[today_bz_chart.tg[5]]] && renderRelationshipRow(1, 6, bz_chart.tg[2], today_bz_chart.tg[5], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[2]]][data.reference_hs_element[today_bz_chart.tg[5]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[today_bz_chart.tg[5]]] && renderRelationshipRow(2, 6, bz_chart.tg[3], today_bz_chart.tg[5], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[3]]][data.reference_hs_element[today_bz_chart.tg[5]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[4]]][data.reference_hs_element[today_bz_chart.tg[5]]] && renderRelationshipRow(3, 6, bz_chart.tg[4], today_bz_chart.tg[5], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[4]]][data.reference_hs_element[today_bz_chart.tg[5]]], "hs")}
              {data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[5]]][data.reference_hs_element[today_bz_chart.tg[5]]] && renderRelationshipRow(4, 6, bz_chart.tg[5], today_bz_chart.tg[5], data.reference_element_cycle[data.reference_hs_element[bz_chart.tg[5]]][data.reference_hs_element[today_bz_chart.tg[5]]], "hs")}

              {data.reference_branch_6_combi[bz_chart.eb[2] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 6, bz_chart.eb[2], today_bz_chart.eb[5], data.reference_branch_6_combi[bz_chart.eb[2] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[3] + today_bz_chart.eb[5]] && renderRelationshipRow(2, 6, bz_chart.eb[3], today_bz_chart.eb[5], data.reference_branch_6_combi[bz_chart.eb[3] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[4] + today_bz_chart.eb[5]] && renderRelationshipRow(3, 6, bz_chart.eb[4], today_bz_chart.eb[5], data.reference_branch_6_combi[bz_chart.eb[4] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_6_combi[bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(4, 6, bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_6_combi[bz_chart.eb[5] + today_bz_chart.eb[5]].type)}

              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 5, bz_chart.eb[2] + ", " + bz_chart.eb[3], today_bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[3] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[4] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 5, bz_chart.eb[2] + ", " + bz_chart.eb[4], today_bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[4] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 5, bz_chart.eb[2] + ", " + bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[2] + bz_chart.eb[5] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[4] + today_bz_chart.eb[5]] && renderRelationshipRow(2, 5, bz_chart.eb[3] + ", " + bz_chart.eb[4], today_bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[4] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(2, 5, bz_chart.eb[3] + ", " + bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[3] + bz_chart.eb[5] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_3_combi[bz_chart.eb[4] + bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(3, 5, bz_chart.eb[4] + ", " + bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_3_combi[bz_chart.eb[4] + bz_chart.eb[5] + today_bz_chart.eb[5]].type)}

              {data.reference_branch_6_clash[bz_chart.eb[2] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 6, bz_chart.eb[2], today_bz_chart.eb[5], data.reference_branch_6_clash[bz_chart.eb[2] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[3] + today_bz_chart.eb[5]] && renderRelationshipRow(2, 6, bz_chart.eb[3], today_bz_chart.eb[5], data.reference_branch_6_clash[bz_chart.eb[3] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[4] + today_bz_chart.eb[5]] && renderRelationshipRow(3, 6, bz_chart.eb[4], today_bz_chart.eb[5], data.reference_branch_6_clash[bz_chart.eb[4] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_6_clash[bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(4, 6, bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_6_clash[bz_chart.eb[5] + today_bz_chart.eb[5]].type)}

              {data.reference_branch_self_punishment[bz_chart.eb[2] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 6, bz_chart.eb[2], today_bz_chart.eb[5], data.reference_branch_self_punishment[bz_chart.eb[2] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[3] + today_bz_chart.eb[5]] && renderRelationshipRow(2, 6, bz_chart.eb[3], today_bz_chart.eb[5], data.reference_branch_self_punishment[bz_chart.eb[3] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[4] + today_bz_chart.eb[5]] && renderRelationshipRow(3, 6, bz_chart.eb[4], today_bz_chart.eb[5], data.reference_branch_self_punishment[bz_chart.eb[4] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_self_punishment[bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(4, 6, bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_self_punishment[bz_chart.eb[5] + today_bz_chart.eb[5]].type)}

              {data.reference_branch_destruction[bz_chart.eb[2] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 6, bz_chart.eb[2], today_bz_chart.eb[5], data.reference_branch_destruction[bz_chart.eb[2] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_destruction[bz_chart.eb[3] + today_bz_chart.eb[5]] && renderRelationshipRow(2, 6, bz_chart.eb[3], today_bz_chart.eb[5], data.reference_branch_destruction[bz_chart.eb[3] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_destruction[bz_chart.eb[4] + today_bz_chart.eb[5]] && renderRelationshipRow(3, 6, bz_chart.eb[4], today_bz_chart.eb[5], data.reference_branch_destruction[bz_chart.eb[4] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_destruction[bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(4, 6, bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_destruction[bz_chart.eb[5] + today_bz_chart.eb[5]].type)}

              {data.reference_branch_harm[bz_chart.eb[2] + today_bz_chart.eb[5]] && renderRelationshipRow(1, 6, bz_chart.eb[2], today_bz_chart.eb[5], data.reference_branch_harm[bz_chart.eb[2] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_harm[bz_chart.eb[3] + today_bz_chart.eb[5]] && renderRelationshipRow(2, 6, bz_chart.eb[3], today_bz_chart.eb[5], data.reference_branch_harm[bz_chart.eb[3] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_harm[bz_chart.eb[4] + today_bz_chart.eb[5]] && renderRelationshipRow(3, 6, bz_chart.eb[4], today_bz_chart.eb[5], data.reference_branch_harm[bz_chart.eb[4] + today_bz_chart.eb[5]].type)}
              {data.reference_branch_harm[bz_chart.eb[5] + today_bz_chart.eb[5]] && renderRelationshipRow(4, 6, bz_chart.eb[5], today_bz_chart.eb[5], data.reference_branch_harm[bz_chart.eb[5] + today_bz_chart.eb[5]].type)}

            </Card>

            <div className={Style.page_break}></div>
            <Card title='Auxiliary Stars' className={Style.being_card}>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>DE Star<br />空亡</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    {data.reference_de[bz_chart.tg[3] + bz_chart.eb[3]].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{star}
                        <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, star, Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[star] + ")"}</span>{index === arr.length - 1 ? '' : ', '}
                      </span>
                    ))}
                    <br />
                    {data.reference_de[bz_chart.tg[3] + bz_chart.eb[3]].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{data.reference_animal_english[star]}{index === arr.length - 1 ? '' : ', '}</span>
                    ))}
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Strangle Star<br />勾绞</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_strangle_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_strangle_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_strangle_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_strangle_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_strangle_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_strangle_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_strangle_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_strangle_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_strangle_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_strangle_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_strangle_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_strangle_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Shooting Star<br />流霞</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_shooting_star[bz_chart.tg[5]], Style.has_element)}>{data.reference_shooting_star[bz_chart.tg[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_shooting_star[bz_chart.tg[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_shooting_star[bz_chart.tg[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_shooting_star[bz_chart.tg[3]], Style.has_element)}>{(bz_chart.tg[5] != bz_chart.tg[3]) ? ", " + data.reference_shooting_star[bz_chart.tg[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_shooting_star[bz_chart.tg[3]], Style.has_element)].join(' ')}>{((bz_chart.tg[5] != bz_chart.tg[3]) ? " (" + data.reference_animal_ping_ying[data.reference_shooting_star[bz_chart.tg[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_shooting_star[bz_chart.tg[5]], Style.has_element)}>{data.reference_animal_english[data.reference_shooting_star[bz_chart.tg[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_shooting_star[bz_chart.tg[3]], Style.has_element)}>{(bz_chart.tg[5] != bz_chart.tg[3]) ? ", " + data.reference_animal_english[data.reference_shooting_star[bz_chart.tg[3]]] : ""}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Blood Knife<br />血刃</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_blood_knife_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_blood_knife_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_blood_knife_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_blood_knife_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_blood_knife_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_blood_knife_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_blood_knife_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_blood_knife_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_blood_knife_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_blood_knife_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_blood_knife_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_blood_knife_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Goat Blade<br />羊刃</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_goat_blade_star[dm], Style.has_element)}>{data.reference_goat_blade_star[dm]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_goat_blade_star[dm], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_goat_blade_star[dm]] + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_goat_blade_star[dm], Style.has_element)}>{data.reference_animal_english[data.reference_goat_blade_star[dm]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Mutual Goat Blade<br />互換羊刃</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    {
                      data.reference_mutal_goat_blade[bz_chart.tg[3] + bz_chart.eb[3]] ? <span>
                        {
                          <span className={data.hasPillarInChart(bz_chart, data.reference_mutal_goat_blade[bz_chart.tg[3] + bz_chart.eb[3]], Style.has_element)}>
                            {data.reference_mutal_goat_blade[bz_chart.tg[3] + bz_chart.eb[3]]}
                            <span>{" (" + data.reference_element_english[data.reference_mutal_goat_blade[bz_chart.tg[3] + bz_chart.eb[3]][0]] + " " + data.reference_animal_ping_ying[data.reference_mutal_goat_blade[bz_chart.tg[3] + bz_chart.eb[3]][1]] + ")"}</span>
                            <br />
                            {
                              data.reference_element_explaination_english[data.reference_mutal_goat_blade[bz_chart.tg[3] + bz_chart.eb[3]][0]] + " " +
                              data.reference_animal_english[data.reference_mutal_goat_blade[bz_chart.tg[3] + bz_chart.eb[3]][1]]
                            }
                          </span>
                        }
                      </span> : "-"
                    }
                  </span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Heavenly Dog Star<br />天狗</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_dog_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_heavenly_dog_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_heavenly_dog_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_heavenly_dog_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_dog_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_heavenly_dog_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_heavenly_dog_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_heavenly_dog_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_dog_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_heavenly_dog_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_dog_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_heavenly_dog_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Death God Star<br />亡神</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_god_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_death_god_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_death_god_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_death_god_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_god_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_death_god_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_death_god_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_death_god_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_god_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_death_god_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_god_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_death_god_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Taiji Nobleman<br />太极贵人</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    {data.taiji_nobleman[dm].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{star}
                        <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, star, Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[star] + ")"}</span>{index === arr.length - 1 ? '' : ', '}
                      </span>
                    ))}
                    <br />
                    {data.taiji_nobleman[dm].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{data.reference_animal_english[star]}{index === arr.length - 1 ? '' : ', '}</span>
                    ))}
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Star of Romance<br />红鸾</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_romance[bz_chart.eb[5]], Style.has_element)}>{data.reference_star_of_romance[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_romance[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_star_of_romance[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_romance[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_star_of_romance[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_romance[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_star_of_romance[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_romance[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_star_of_romance[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_romance[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_star_of_romance[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Star of Happiness<br />天喜</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_happiness[bz_chart.eb[5]], Style.has_element)}>{data.reference_star_of_happiness[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_happiness[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_star_of_happiness[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_happiness[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_star_of_happiness[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_happiness[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_star_of_happiness[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_happiness[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_star_of_happiness[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_happiness[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_star_of_happiness[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Heavenly Virtue Star<br />天德贵人</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_virtue_star[bz_chart.eb[4]], Style.has_element)}>{data.reference_heavenly_virtue_star[bz_chart.eb[4]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_heavenly_virtue_star[bz_chart.eb[4]], Style.has_element)].join(' ')}>{" (" + (data.reference_element_english[data.reference_heavenly_virtue_star[bz_chart.eb[4]]] ? data.reference_element_english[data.reference_heavenly_virtue_star[bz_chart.eb[4]]] : data.reference_animal_ping_ying[data.reference_heavenly_virtue_star[bz_chart.eb[4]]]) + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_virtue_star[bz_chart.eb[4]], Style.has_element)}>{data.reference_element_explaination_english[data.reference_heavenly_virtue_star[bz_chart.eb[4]]] ? data.reference_element_explaination_english[data.reference_heavenly_virtue_star[bz_chart.eb[4]]] : data.reference_animal_english[data.reference_heavenly_virtue_star[bz_chart.eb[4]]]}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Monthly Virtue Star<br />月德贵人</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_monthly_virtue_star[bz_chart.eb[4]], Style.has_element)}>{data.reference_monthly_virtue_star[bz_chart.eb[4]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_monthly_virtue_star[bz_chart.eb[4]], Style.has_element)].join(' ')}>{" (" + data.reference_element_english[data.reference_monthly_virtue_star[bz_chart.eb[4]]] + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_monthly_virtue_star[bz_chart.eb[4]], Style.has_element)}>{data.reference_element_explaination_english[data.reference_monthly_virtue_star[bz_chart.eb[4]]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Five Ghost Star<br />五鬼</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_five_ghost_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_five_ghost_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_five_ghost_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_five_ghost_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_five_ghost_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_five_ghost_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_five_ghost_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_five_ghost_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_five_ghost_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_five_ghost_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_five_ghost_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_five_ghost_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Misfortune Star<br />五黄</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_misfortune_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_misfortune_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_misfortune_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_misfortune_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_misfortune_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_misfortune_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_misfortune_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_misfortune_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_misfortune_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_misfortune_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_misfortune_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_misfortune_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>The Sword Star<br />阳刃</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    {data.reference_sword_star[dm].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{star}
                        <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, star, Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[star] + ")"}</span>{index === arr.length - 1 ? '' : ', '}
                      </span>
                    ))}
                    <br />
                    {data.reference_sword_star[dm].map((star, index, arr) => (
                      <span className={data.hasElementInChart(bz_chart, star, Style.has_element)}>{data.reference_animal_english[star]}{index === arr.length - 1 ? '' : ', '}</span>
                    ))}
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Prosperity Star<br />禄神</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_prosperity_star[dm], Style.has_element)}>{data.reference_prosperity_star[dm]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_prosperity_star[dm], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_prosperity_star[dm]] + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_prosperity_star[dm], Style.has_element)}>{data.reference_animal_english[data.reference_prosperity_star[dm]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Golden Carriage Star<br />金舆</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_golden_carriage_star[dm], Style.has_element)}>{data.reference_golden_carriage_star[dm]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_golden_carriage_star[dm], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_golden_carriage_star[dm]] + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_golden_carriage_star[dm], Style.has_element)}>{data.reference_animal_english[data.reference_golden_carriage_star[dm]]}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>General Star<br />将星</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_general[bz_chart.eb[5]], Style.has_element)}>{data.reference_star_of_general[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_general[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_star_of_general[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_general[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_star_of_general[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_general[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_star_of_general[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_general[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_star_of_general[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_general[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_star_of_general[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Elegant Star<br />华盖</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_fine_arts[bz_chart.eb[5]], Style.has_element)}>{data.reference_star_of_fine_arts[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_fine_arts[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_star_of_fine_arts[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_fine_arts[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_star_of_fine_arts[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_fine_arts[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_star_of_fine_arts[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_fine_arts[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_star_of_fine_arts[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_fine_arts[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_star_of_fine_arts[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Robbing Star<br />劫煞</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_robbing_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_robbing_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_robbing_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_robbing_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_robbing_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_robbing_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_robbing_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_robbing_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_robbing_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_robbing_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_robbing_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_robbing_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
              </Grid>
              {/* 勾绞 Quarrel, Dispute, Meeting the Law, Hook Edge */}
              {/* 红鸾 Red Matchmaker, Celebration, Promotion, Red Phoenix */}
              {/* 学堂 Professor, Literacy, Learning Hall, Precious Educational Hall */}
              {/* 飞刃 Flying Blade, Flying Knife, Flying Dagger, Star of Blood */}
              {/* 红艳桃花 Red Chamber, Affair, Beautiful Peach Blossom, Peach Blossom, Red Envy, Passions, Red Peach Blossom */}
              {/* 太极 TaiJi */}
              {/* FuXingGuiRen / 福星 Beneficiary, Private Luck */}
              {/* TianChuGuiRen / 天厨 Culinary, Heavenly Kitchen */}
              {/* TianGuanGuiRen / 天官 Clemency, Heavenly Administration */}
              {/* TianFuGuiRen / 天赋 Career, Heavenly Luck */}
              {/* JieDuGuiRen / 节度 Virtue, Right Measure */}

              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Death Star<br />亡神</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_death_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_death_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_death_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_death_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_death_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_death_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_death_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_death_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_death_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Lonesome Star<br />寡宿</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_lonesome_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_lonesome_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_lonesome_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_lonesome_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_lonesome_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_lonesome_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_lonesome_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_lonesome_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_lonesome_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_lonesome_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_lonesome_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_lonesome_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Funeral Stars<br />丧门</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_mourning_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_mourning_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_mourning_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_mourning_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_mourning_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_mourning_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_mourning_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_mourning_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_mourning_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_mourning_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_mourning_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_mourning_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Crying Star<br />吊客</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_crying_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_crying_star[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_crying_star[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_crying_star[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_crying_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_crying_star[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_crying_star[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_crying_star[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_crying_star[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_crying_star[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_crying_star[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_crying_star[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Star of Institution<br /></span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_institution[dm], Style.has_element)}>{data.reference_star_of_institution[dm]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_star_of_institution[dm], Style.has_element)].join(' ')}>{" (" + (data.reference_animal_ping_ying[data.reference_star_of_institution[dm]] ? data.reference_animal_ping_ying[data.reference_star_of_institution[dm]] : data.reference_element_english[data.reference_star_of_institution[dm]]) + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_star_of_institution[dm], Style.has_element)}>{data.reference_animal_english[data.reference_star_of_institution[dm]] ? data.reference_animal_english[data.reference_star_of_institution[dm]] : data.reference_element_explaination_english[data.reference_star_of_institution[dm]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Dragon Virtue<br />龙德</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_dragon_virtue[bz_chart.eb[5]], Style.has_element)}>{data.reference_dragon_virtue[bz_chart.eb[5]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_dragon_virtue[bz_chart.eb[5]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_dragon_virtue[bz_chart.eb[5]]] + ")"}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_dragon_virtue[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_dragon_virtue[bz_chart.eb[3]] : ""}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_dragon_virtue[bz_chart.eb[3]], Style.has_element)].join(' ')}>{((bz_chart.eb[5] != bz_chart.eb[3]) ? " (" + data.reference_animal_ping_ying[data.reference_dragon_virtue[bz_chart.eb[3]]] + ")" : "")}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_dragon_virtue[bz_chart.eb[5]], Style.has_element)}>{data.reference_animal_english[data.reference_dragon_virtue[bz_chart.eb[5]]]}</span>
                    <span className={data.hasElementInChart(bz_chart, data.reference_dragon_virtue[bz_chart.eb[3]], Style.has_element)}>{(bz_chart.eb[5] != bz_chart.eb[3]) ? ", " + data.reference_animal_english[data.reference_dragon_virtue[bz_chart.eb[3]]] : ""}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Heavenly Wealth<br />天财</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_wealth[dm], Style.has_element)}>{data.reference_heavenly_wealth[dm]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_heavenly_wealth[dm], Style.has_element)].join(' ')}>{" (" + data.reference_element_english[data.reference_heavenly_wealth[dm]] + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_wealth[dm], Style.has_element)}>{data.reference_element_explaination_english[data.reference_heavenly_wealth[dm]]}</span>
                  </span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Heavenly Doctor Star<br />天医</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_doctor_star[bz_chart.eb[4]], Style.has_element)}>{data.reference_heavenly_doctor_star[bz_chart.eb[4]]}</span>
                    <span className={[Style.infor_smaller, data.hasElementInChart(bz_chart, data.reference_heavenly_doctor_star[bz_chart.eb[4]], Style.has_element)].join(' ')}>{" (" + data.reference_animal_ping_ying[data.reference_heavenly_doctor_star[bz_chart.eb[4]]] + ")"}</span>
                    <br />
                    <span className={data.hasElementInChart(bz_chart, data.reference_heavenly_doctor_star[bz_chart.eb[4]], Style.has_element)}>{data.reference_animal_english[data.reference_heavenly_doctor_star[bz_chart.eb[4]]]}</span>
                  </span>
                </Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='1'>
                <Grid className={Style.basic_info_table_grid_long} cols='1'><span className={Style.bz_container_header}>Special Information<br /></span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Refined Days<br /></span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isRefinedDays(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isRefinedDays(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Blessings Star<br />月福星</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isBlessingStar(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isBlessingStar(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Heavenly Nobleman<br />天上三奇贵人</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isHeavenlyNobleman(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isHeavenlyNobleman(bz_chart, "No")}</span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Earthly Nobleman<br />地下三奇贵人</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isEarthlyNobleman(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isEarthlyNobleman(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Human Nobleman<br />人中三奇贵人</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isHumanNobleman(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isHumanNobleman(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Atrocious Star<br /></span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isAstrociousStar(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isAstrociousStar(bz_chart, "No")}</span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Atrocious Star Clash<br /></span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isAstrociousClashStar(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isAstrociousClashStar(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Commannder Nobleman<br />魁罡</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isCommanderNobleman(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isCommanderNobleman(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Gold Spirit<br /></span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isGoldenSpirit(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isGoldenSpirit(bz_chart, "No")}</span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>10 Enlightened Days<br />十灵日</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.is10EnlightenedDays(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.is10EnlightenedDays(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Heaven's Gate<br />天罗</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isHeavensGate(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isHeavensGate(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Earth's Door<br />地网</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isEarthGate(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isEarthGate(bz_chart, "No")}</span></Grid>
              </Grid>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Wild Flower of Romance<br />遍野桃花</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isWildFlowerOfRomance(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isWildFlowerOfRomance(bz_chart, "No")}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Naked Flower of Romance<br />裸体桃花</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={[Style.main_element_explaination_eng, data.isNakedFlowerOfRomance(bz_chart, false) ? Style.has_element : ""].join(' ')}>{data.isNakedFlowerOfRomance(bz_chart, "No")}</span></Grid>
              </Grid>
            </Card>
          </Card>
        </Grid>
      }
    </Animate >
  );
}
