import { PersonalBaziChart } from 'views/personal/personalbazichart';
import { ReadingConceptionLifePalace } from 'views/readingconceptionlife/readingconceptionlife';

const Routes = [
  {
    path: '/personalbazichart',
    view: PersonalBaziChart,
    layout: 'app',
    title: 'Bazi Chart'
  },
  {
    path: '/readingconceptionlifepalace',
    view: ReadingConceptionLifePalace,
    layout: 'app',
    title: 'Conception and Life Palace Reading'
  },

]

export default Routes;