import React, { Fragment, useState, useEffect } from 'react';
import { Label } from 'components/lib';
import TimePicker from 'rc-time-picker';
import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';
import Style from './input.module.scss';
import { FALSE } from 'node-sass';
import moment from 'moment';

export function TimeInput(props){

  // State to manage the selected time
  const [time, setTime] = useState(() => {
    if (props.value && props.value.indexOf(':') && props.value.length >= 5) {
      const [hour, minute] = props.value.split(':').map(Number);
      return moment().set({ hour, minute });
    }
    return moment(); // Default to the current time
  });

  // Update `time` whenever `props.value` changes
  useEffect(() => {
    if (props.value && props.value.indexOf(':') && props.value.length >= 5) {
      const [hour, minute] = props.value.split(':').map(Number);
      setTime(moment().set({ hour, minute }));
    }
  }, [props.value]);

  function formatTimeString(d){

    return d.format("HH:mm")

  }

  // Handle time change
  function handleChange(date) {
    if (date) {
      props.onChange(props.name, formatTimeString(date), true);
      setTime(date);
    }
  }
  
  return (
    <Fragment>
      {props.label && <Label text={props.label} />}
      <TimePicker
        defaultValue={time}
        value={time} // Controlled by state
        onChange={handleChange}
        showSecond={false}
        className={Style.textbox}
      />
    </Fragment>
  );
}