import React, { Fragment, useState, useEffect } from 'react';
import { Label } from 'components/lib';
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import DatePicker from 'react-modern-calendar-datepicker';
import { DropdownDate, DropdownComponent } from 'react-dropdown-date';
import Style from './input.module.scss';

// format is YYYY-MM-DD

export function DateInput(props) {

  // Initialize default value for the date
  let initialDate = props.value;

  if (initialDate && initialDate.indexOf('-') && initialDate.length >= 10) {
    const [year, month, day] = initialDate.split('-').map(Number);
    initialDate = formatDateString(year, month, day);
  } else {
    initialDate = "1980-01-01"; // Default date
  }

  // console.log("date", date);

  // const [state, setState] = useState({ data: null, selectedDate: date });

  const [selectedDate, setSelectedDate] = useState(initialDate);
  useEffect(() => {
    // Update `selectedDate` when `props.value` changes
    if (props.value && props.value !== selectedDate) {
      const [year, month, day] = props.value.split('-').map(Number);
      setSelectedDate(formatDateString(year, month, day));
    }
  }, [props.value]);

  function formatDateString(y, m, d) {
    // console.log("YMD", y, m, d);

    return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`

  }

  function formatDate(date) {	// formats a JS date to 'yyyy-mm-dd'
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  return (
    <Fragment>

      {props.label &&
        <Label text={props.label} />}
      {selectedDate &&
        <DropdownDate
          startDate={                       // optional, if not provided 1900-01-01 is startDate
            props.min                    // 'yyyy-mm-dd' format only
          }
          endDate={                         // optional, if not provided current date is endDate
            props.max                    // 'yyyy-mm-dd' format only
          }
          selectedDate={                    // optional
            selectedDate        // 'yyyy-mm-dd' format only
          }
          order={[                          // optional // Order of the dropdowns
            DropdownComponent.day,
            DropdownComponent.month,
            DropdownComponent.year
          ]}
          classes={                         // optional
            {
              yearContainer: Style.input_date,
              monthContainer: Style.input_date,
              dayContainer: Style.input_date
            }
          }
          onDateChange={(date) => {         // optional
            // console.log("date", date);
            const formattedDate = formatDate(date);
            setSelectedDate(formattedDate);
            props.onChange(props.name, formattedDate, true);
          }}
        />
      }
    </Fragment>
  )
}